import Flickity from 'flickity';

// Card Slider
const cardSlider = (() => {
    const flkty = new Flickity('.card-slider__slide', {
        prevNextButtons: false,
        pageDots: false,
        cellAlign: 'left',
    });

    if (matchMedia('screen and (min-width: 768px)').matches) {
        flkty.isDraggable = false;
    }
})();

const newsSlider = (() => {
    const heroSlider = document.querySelectorAll('.hero__image--slider');

    const initialiseCompontent = () => {
        heroSlider.forEach((el, i) => {
            const sliderElement = el.querySelector('.hero__image-slides');
            const slides = el.querySelectorAll('.hero__slide-images');
            const prevButton = el.querySelector('.hero__image-slides__navigation-left');
            const nextButton = el.querySelector('.hero__image-slides__navigation-right');
            const slideIndexIndicator = el.querySelector('.hero__image-slides__navigation p span');

            if (slides.length > 1) {
                const sliderOptions = {
                    pageDots: false,
                    prevNextButtons: false,
                };
                const slider = new Flickity(sliderElement, sliderOptions);
    
                __sliderArrows(slider, prevButton, nextButton);
    
                slider.on('change', (i) => {
                    slideIndexIndicator.textContent = i + 1;
                });
            }
        })
    };

    const __sliderArrows = (slider, prevButton, nextButton) => {
        prevButton.addEventListener('click', function () {
            slider.previous();
        });

        nextButton.addEventListener('click', function () {
            slider.next();
        });
    };
    return {
        init: initialiseCompontent,
    };
})();
newsSlider.init();

const mobileMenu = (() => {
    const mobileMenuTrigger = document.querySelector('.mobile-header__burger'),
        bodyRoot = document.getElementsByTagName('body')[0],
        mobileMenuChildren = document.querySelectorAll('.mobile-nav .menu-item-has-children');

    const initMobileMenu = () => {
        if (mobileMenuTrigger) {
            __toggleMobileMenu(mobileMenuTrigger);
            __toggleSubMenu(mobileMenuChildren);
        }
    };

    const __toggleMobileMenu = (el) => {
        el.addEventListener('click', function () {
            bodyRoot.classList.toggle('mobile-menu-active');
            bodyRoot.classList.toggle('no-scroll');

            mobileMenuChildren.forEach(function (el) {
                if (el.classList.contains('is-active')) {
                    el.classList.remove('is-active');
                    bodyRoot.classList.remove('sub-menu-triggered');
                }
            });
        });
    };

    const __toggleSubMenu = (menuChildren) => {
        menuChildren.forEach(function (el) {
            el.querySelector('a').addEventListener('click', function () {
                el.classList.toggle('is-active');
                bodyRoot.classList.toggle('sub-menu-triggered');
            });
        });
    };

    return {
        init: initMobileMenu,
    };
})();
mobileMenu.init();

const accordion = (() => {
    const accordions = document.querySelectorAll('.accordion');

    accordions.forEach(function (el) {
        const accordionTrigger = el.querySelector('.accordion__heading');
        const accordionContent = el.querySelector('.accordion__content');

        accordionTrigger.addEventListener('click', function () {
            this.classList.toggle('is-active');
            if (accordionContent.style.maxHeight) {
                accordionContent.style.maxHeight = null;
                accordionContent.style.margin = '0';
            } else {
                accordionContent.style.maxHeight = accordionContent.scrollHeight + 'px';
                accordionContent.style.margin = '40px 0 20px 0';
            }
        });
    });
})();

const translate = (() => {

    const translateToggle = document.querySelector('.toggle-translate');
    const translateContainer = document.querySelector('.google-translate');

    translateToggle.addEventListener('click', (e) => {
        e.preventDefault();
        translateContainer.classList.toggle('d-lg-block');
        googleTranslate();

    })

    const googleTranslate = () => {
        new google.translate.TranslateElement(
            { pageLanguage: 'en' },
            'google_translate_element'
          );
    }
})();